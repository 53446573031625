import SectionTitle from "../Common/SectionTitle";
import SingleFeature from "./SingleFeature";
import featuresData from "./featuresData";

const Features = () => {
  return (<div className="dark bg-[#FCFCFC] dark:bg-black">


      <section id="features" className="py-16 md:py-20 lg:py-28">
        <div className="container">
          <SectionTitle
            title="Main Features"
            paragraph="This is a list of our main features that we offer to our clients.
          We are always working on new features to make our platform better."
            center
          />

          <div className="grid grid-cols-1 gap-x-8 gap-y-14 md:grid-cols-2 lg:grid-cols-3">
            {featuresData.map((feature) => (<SingleFeature key={feature.id} feature={feature}/>))}
          </div>
        </div>
      </section>
    </div>);
};

export default Features;
