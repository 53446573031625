import {useGetUser} from "../components/utils/auth";

type FetchOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: BodyInit | null;
  mode?: RequestMode;
  credentials?: RequestCredentials;
  cache?: RequestCache;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  integrity?: string;
  keepalive?: boolean;
  signal?: AbortSignal | null;
  window?: any;
};

const useCustomFetch = () => {
  const userSub = useGetUser()?.sub;

  return async (url: string, options: FetchOptions = {}) => {
    const defaultHeaders = {
      'Authorization': `Bearer ${userSub}`,
    };

    const mergedOptions = {
      ...options, headers: {
        ...defaultHeaders, ...options.headers,
      },
    };

    return fetch(url, mergedOptions);
  };
};

export default useCustomFetch;
