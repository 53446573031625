import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const JoinHubLogo = () => {
  return (
    <h1 className="mb-5 text-7xl font-extrabold leading-tight text-black dark:text-white sm:text-5xl sm:leading-tight md:text-6xl md:leading-tight">
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600">
        JoinHub
      </span>
    </h1>
  );
};

const Whitelist: React.FC<Props> = ({ children }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  const handlePasswordSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === 'whitelist') {
      localStorage.setItem('isAuthenticated', 'true');
      navigate('/'); // Redirect to home page
    } else {
      alert('Incorrect password');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('isAuthenticated') === null) {
      localStorage.setItem('isAuthenticated', 'false');
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800">
        <JoinHubLogo />
        <p className="mb-4 text-lg text-red-600">Restricted Access</p>
        <form onSubmit={handlePasswordSubmit} className="w-full max-w-md mt-6">
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-200 text-sm font-bold mb-2">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
              type="submit"
            >
              Enter
            </button>
          </div>
        </form>
      </div>
    );
  }

  return <>{children}</>;
};

export default Whitelist;
