import {useAuth0} from "@auth0/auth0-react";

export function useIsLoggedIn() {
  const { user } = useAuth0();
  return !!user;
}

export function useGetUser() {
  const { user } = useAuth0();
  return user;
}


