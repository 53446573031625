import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Auth0Provider} from "@auth0/auth0-react";
import App from "./App.tsx";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="https://joinhub.eu.auth0.com"
    clientId="JB9JHkmxnA0eUeHMN43S2FAF7xmRJCzf"
    useRefreshTokens
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
>
  <App />
</Auth0Provider>,
);
