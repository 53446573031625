import {Menu} from "../../types/menu";

export function useMenuData() {
  const menuData: Menu[] = [
    {
      id: 1,
      title: "Home",
      path: "/",
      newTab: false,
    },
    {
      id: 2,
      title: "About",
      path: "/about",
      newTab: false,
    },
    {
      id: 4,
      title: "Pages",
      newTab: false,
      submenu: [
        {
          id: 41,
          title: "About Page",
          path: "/about",
          newTab: false,
        },
        {
          id: 48,
          title: "Error Page",
          path: "/error",
          newTab: false,
        },
      ],
    },
  ];
  return menuData;
}
