import React from 'react';
import {getLogoUrl} from "../community-admin-panel";
import {Community} from "../../types/community";

interface IntegrationModalProps {
  title: string;
  community: Community
  onClose: () => void;
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({title, community, onClose}) => {

  function startOnboarding(title: string) {
    let oauthUrl: string;

    if (title === "Discord") {
      const clientId = "1246553908986380328";
      const permissions = "8";
      const redirectUri = encodeURIComponent(`https://joinhub.me/onboarding/${title.toLowerCase()}/successful`);
      const scope = encodeURIComponent("identify email bot messages.read guilds.members.read");
      const state = encodeURIComponent(community.name);
      oauthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&permissions=${permissions}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

      window.location.href = oauthUrl;
    }
  }

  return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="relative bg-dark p-6 rounded-lg shadow-lg max-w-sm w-full">
      <img className="w-16 h-16 mb-4" src={getLogoUrl(title)} alt={title}/>
      <div className="text-center mb-6">
        <h2 className="text-white text-2xl font-bold">Start the {title} onboarding process!</h2>
      </div>
      <div className="text-center">
        {title.toLowerCase() === 'whatsapp' || title.toLowerCase() === 'telegram' ? (
          <p className="text-red-500">Onboarding for {title} is not yet available.</p>
        ) : (
          <button onClick={() => startOnboarding(title)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            Start
          </button>
        )}
      </div>
      <button
        className="absolute top-2 right-2 text-white text-2xl font-bold focus:outline-none"
        onClick={onClose}
      >
        &times;
      </button>
    </div>
  </div>);
};

export default IntegrationModal;
