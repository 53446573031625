import React, {useEffect, useState} from 'react';
import {Community, Guild, Member} from '../../types/community';
import IntegrationModal from "../Integration/IntegrationModal";
import {findChannelData, findGuildData} from "../../api/api";

interface CommunityAdminPanelProps {
  community: Community;
}

interface IntegrationCardProps {
  title: string;
  logo: string;
  onboarded: boolean;
  apiKey: string;
  inviteUrl: string;
  showSettings: boolean;
  toggleSettings: () => void;
  handleSave: (apiKey: string, inviteUrl: string, channelId: string) => void;
  community?: Community;
  channelId?: string;
  guild: Guild;
  setGuild: (value: (((prevState: any) => any) | Guild)) => void;
  channelData?: any[];
  setChannelData?: (value: (((prevState: any[]) => any[]) | any[])) => void;
}

const logos: Record<string, string> = {
  Discord: 'https://logowiki.net/uploads/logo/d/discord.svg',
  WhatsApp: 'https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg',
  Telegram: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg',
};

export function getLogoUrl(name: string) {
  return logos[name] || '';
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
                                                           title,
                                                           logo,
                                                           onboarded,
                                                           apiKey,
                                                           inviteUrl,
                                                           showSettings,
                                                           toggleSettings,
                                                           handleSave,
                                                           community,
                                                           channelId,
                                                           guild,
                                                           setGuild,
                                                           channelData,
                                                           setChannelData,
                                                         }) => {
  const [newApiKey] = useState(apiKey);
  const [newInviteUrl] = useState(inviteUrl);
  const [newChannelId, setNewChannelId] = useState(channelId);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (showSettings) {
      const fetchMembers = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${community.name}/integrations/${title}/${guild.id}/members`);
          const data = await response.json();
          setMembers(data);
        } catch (error) {
          console.error('Error fetching members:', error);
        }
      };

      fetchMembers();
    }
  }, [community.name, showSettings, guild, title]);

  const handleSaveClick = () => {
    handleSave(newApiKey, newInviteUrl, newChannelId);
  };

  const handleChannelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNewChannelId(event.target.value);
  };

  const MemberItem: React.FC<{ member: Member }> = ({member}) => (
    <li className="py-2 flex justify-between items-center">
      <div>
        <p className="text-lg font-medium">{member.user.username}</p>
        <p className="text-sm text-gray-400">{member.user.global_name}</p>
        <p className="text-sm text-gray-400">Subscribed since {member.joined_at}</p>
      </div>
      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded">
        Remove
      </button>
    </li>);

  const ChannelSelect: React.FC<{
    channelData: any[];
    newChannelId: string;
    handleChannelChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  }> = ({channelData, newChannelId, handleChannelChange}) => (
    <select className="bg-gray-800 text-white p-2 rounded" value={newChannelId} onChange={handleChannelChange}>
      {channelData.map((channel, index) => (<option key={index} value={channel.id}>
        {channel.name}
      </option>))}
    </select>);

  return (<div className="rounded overflow-hidden shadow-lg m-4 text-center bg-gray-800">
    <div className="flex flex-col justify-center items-center h-full p-4">
      <img className="w-16 h-16 mb-4" src={logo} alt={title}/>
      <div className="font-bold text-xl mb-2">{title} Integration</div>
      <p className="text-gray-400 text-base">Integrate your community with {title}.</p>
      <div className="mt-4">
        <p
          className={`font-bold py-2 px-4 rounded mb-2 ${onboarded ? 'bg-blue-500 hover:bg-blue-700 text-white' : 'bg-red-500 text-white'}`}>
          {onboarded ? '✅️ Onboarded' : '❌ Not onboarded yet'}
        </p>
        {onboarded && (<>
          <button
            onClick={toggleSettings}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mb-2"
          >
            Settings
          </button>
          {showSettings && (<div className="bg-gray-700 p-8 rounded-lg text-left">
            <h3 className="text-3xl font-bold mb-6 text-white">Integration Settings</h3>
            <h3 className="text-xl font-bold mb-4">Members Overview</h3>
            <ul className="divide-y divide-gray-700">
              {members.map((member) => (<li key={member.user.id} className="py-4 flex justify-between items-center">
                <div>
                  <p className="text-xl font-medium">{member.user.username}</p>
                  <p className="text-md text-gray-400">{member.user.global_name}</p>
                  <p className="text-md text-gray-400">Subscribed
                    since {new Date(member.joined_at).toLocaleString()}</p>
                </div>
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Remove
                </button>
              </li>))}
            </ul>
            <p className="text-xl text-gray-300 my-4">
              Guild onboarded: {guild.id}
            </p>
            <p className="text-xl text-gray-300 my-4">Guild name: {guild.name}</p>
            <p className="text-xl text-gray-300 my-4">Channel: </p>
            <ChannelSelect channelData={channelData} newChannelId={newChannelId}
                           handleChannelChange={handleChannelChange}/>
            <br/>
            <button
              onClick={handleSaveClick}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded mt-4"
            >
              Save
            </button>
          </div>)}
        </>)}
        {!onboarded && title.toLowerCase() === 'discord' && (<>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
            onClick={() => setShowIntegrationModal(true)}
          >
            Start {title} onboarding
          </button>
          {showIntegrationModal && (<IntegrationModal
            title={title}
            community={community}
            onClose={() => setShowIntegrationModal(false)}
          />)}
        </>)}
        {!onboarded && title.toLowerCase() !== 'discord' && (
          <p className="text-red-500">Onboarding for {title} is not yet available.</p>)}
      </div>
    </div>
  </div>);
};

const CommunityAdminPanel: React.FC<CommunityAdminPanelProps> = ({community}) => {
  const [activeTab, setActiveTab] = useState('settings');
  const [showIntegrationSettings, setShowIntegrationSettings] = useState<Record<string, boolean>>({});
  const [guild, setGuild] = useState<any>('');
  const [channelData, setChannelData] = useState<any[]>([]);

  const toggleSettings = async (title: string) => {
    const integration = community?.integrations.find((i) => i.name === title);
    if (integration) {
      try {
        const guildData = await findGuildData(community.name, title, integration.guild_id);
        setGuild(guildData);
        const channelData = await findChannelData(community.name, title, integration.guild_id);
        setChannelData(channelData);
      } catch (error) {
        console.error('Error in toggleSettings:', error);
      }
    }
    setShowIntegrationSettings((prevState) => ({
      ...prevState, [title]: !prevState[title],
    }));
  };

  const handleSave = (community_name: string, integration_name: string, api_key: string, invite_url: string, channel_id: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${community_name}/integrations/${integration_name}`, {
      method: 'PUT', headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({api_key, invite_url, channel_id}),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle success, e.g., show a notification or update the state
        console.log('Settings updated:', data);
      })
      .catch((error) => {
        console.error('Error updating settings:', error);
      });
  };

  return (<div className="text-white p-5">
    <div className="tabs mb-5 flex">
      <button
        onClick={() => setActiveTab('settings')}
        className={`px-4 py-2 mr-2 rounded ${activeTab === 'settings' ? 'bg-blue-500' : 'bg-gray-700'}`}
      >
        Community Settings
      </button>
      <button
        onClick={() => setActiveTab('integrations')}
        className={`px-4 py-2 rounded ${activeTab === 'integrations' ? 'bg-blue-500' : 'bg-gray-700'}`}
      >
        Platform Integrations
      </button>
    </div>

    {activeTab === 'integrations' && (<div className="flex flex-wrap justify-around">
      {community.integrations.map((integration) => (<IntegrationCard
        key={integration.name}
        title={integration.name}
        logo={getLogoUrl(integration.name)}
        onboarded={integration.onboarding_successful}
        apiKey={integration.api_key}
        community={community}
        inviteUrl={integration.invite_url}
        channelId={integration.channel_id}
        showSettings={!!showIntegrationSettings[integration.name]}
        toggleSettings={() => toggleSettings(integration.name)}
        handleSave={(apiKey, inviteUrl, channelId) => handleSave(community.name, integration.name, apiKey, inviteUrl, channelId)}
        guild={guild}
        setGuild={setGuild}
        channelData={channelData}
        setChannelData={setChannelData}
      />))}
    </div>)}

    {activeTab === 'settings' && <div>Community Settings</div>}
  </div>);
};

export default CommunityAdminPanel;
