import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

const ProfilePage = () => {
  const {logout} = useAuth0();

  const settings = [{id: 'darkMode', label: 'Enable dark mode'}, {
    id: 'emailNotifications', label: 'Enable email notifications'
  }, {id: 'privacyMode', label: 'Enable privacy mode'},];

  const SvgIcon = (<svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
      fill="#3056D3"
      stroke="#3056D3"
      strokeWidth="0.4"
    />
  </svg>);

  // Create a state variable for each setting
  const [darkMode, setDarkMode] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [privacyMode, setPrivacyMode] = useState(false);

  // Map setting ids to their corresponding state variables and setters
  const settingStates = {
    darkMode: [darkMode, setDarkMode],
    emailNotifications: [emailNotifications, setEmailNotifications],
    privacyMode: [privacyMode, setPrivacyMode],
  };

  return (

    <section className="pb-[30px] pt-[30px]">
      <div className="container">
        <h3 className="mb-5 text-2xl font-bold text-body-color dark:text-dark">Profile Settings</h3>
        <p className="mb-10 text-lg text-body-color dark:text-dark/70">
          Manage your profile settings here.</p>

        {settings.map((setting) => {
          const [isChecked, setChecked] = settingStates[setting.id];
          return (<div key={setting.id} className="mb-5 flex">
            <label
              htmlFor={setting.id}
              className="flex cursor-pointer select-none text-sm font-medium text-body-color"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id={setting.id}
                  checked={isChecked}
                  onChange={() => setChecked(!isChecked)}
                  className="sr-only"
                />
                <div
                  className="box mr-4 mt-1 flex h-5 w-5 items-center justify-center rounded border border-body-color border-opacity-20 dark:border-black dark:border-opacity-10"
                >
                  <span className={isChecked ? '' : 'opacity-0'}>{SvgIcon}</span>
                </div>
              </div>
              <span>{setting.label}</span>
            </label>
          </div>);
        })}
        <button onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
          className="px-8 py-3 mt-5 text-lg font-medium text-white bg-red-500 rounded hover:bg-red-600">
          Log Out
        </button>
      </div>
    </section>)

};

export default ProfilePage;
