import React, {useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import {InviteResponse} from "../../types/community";
import useCustomFetch from "../../api/customFetch";
import {toast} from "react-toastify";

const GuildInviteQR = ({communityName, integrationName, channelId}) => {
  const [inviteUrl, setInviteUrl] = useState('');
  const customFetch = useCustomFetch(); // Use useCustomFetch

  useEffect(() => {
    const createInvite = async () => {
      try {
        const response = await customFetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${communityName}/integrations/${integrationName}/channel/${channelId}/invite`, {
          method: 'POST', headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        const data: InviteResponse = await response.json();
        console.log(data);
        setInviteUrl("https://discord.gg/" + data.code);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    };

    createInvite();
  }, [communityName, integrationName, channelId]);

  return (<div>
    {inviteUrl && (<>
      <QRCode value={inviteUrl} size={256}/>
      <p style={{font: 'Inter', marginTop: '20px', color: '#E3E8EF', fontSize: '18px'}}>Or, you can simply click
        this:</p>
      <div className="text-center">
        <a href={inviteUrl} target="_blank" rel="noreferrer" className="font-bold text-xl text-blue-500 no-underline mt-2">Invite link</a>
      </div>
    </>)}
  </div>);
};

export default GuildInviteQR;
