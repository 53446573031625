import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollUp from "./components/Common/ScrollUp";

const Layout: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  return (<div className="dark bg-[#FCFCFC] dark:bg-black">
      <Header/>
      {children}
      <Footer/>
      <ScrollUp/>
    </div>);
};

export default Layout;
