import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AboutSectionOne from './components/About copy/AboutSectionOne';
import Hero from './components/Hero';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Breadcrumb from './components/Common/Breadcrumb';
import Layout from './Layout';
import SigninPage from "./components/signin/page";
import ProfilePage from "./components/profile/page";
import CommunityBuilder from "./components/CommunityBuilder";
import {Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommunityPage from "./components/community/page";
import SuccessfulIntegration from './components/Integration/SuccessfulIntegration';
import Whitelist from "./components/signin/Whitelist";

const App: React.FC = () => {

  return (<div className="dark bg-[#FCFCFC] dark:bg-black">
    <Router>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        className="toast-container"
      />
      <Whitelist>

        <Routes>

          <Route path="/" element={<Layout>
            <div className="dark bg-[#FCFCFC] dark:bg-black">
              <Hero/>
              <Features/>
              <Pricing/>
              <AboutSectionOne/>
            </div>
          </Layout>}/>
          <Route path="/about" element={<Layout>
            <Breadcrumb pageName="About Page"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius eros eget sapien consectetur ultrices. Ut quis dapibus libero."/>
            <AboutSectionOne/>
          </Layout>}/>
          <Route path="/signin" element={<Layout>
            <Breadcrumb pageName="Signin Page"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius eros eget sapien consectetur ultrices. Ut quis dapibus libero."/>
            <SigninPage/>
          </Layout>}/>
          <Route path="/community" element={<Layout>
            <Breadcrumb pageName="Community builder page"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius eros eget sapien consectetur ultrices. Ut quis dapibus libero."/>
            <CommunityBuilder/>
          </Layout>}/>
          <Route path="/community/:communityName" element={<Layout>
            <Breadcrumb pageName={"My community"} description={"Community page description"}/>
            <CommunityPage/>
          </Layout>}/>
          <Route path="/profile" element={<Layout>
            <Breadcrumb pageName="Profile Page"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius eros eget sapien consectetur ultrices. Ut quis dapibus libero."/>
            <ProfilePage/>
          </Layout>}/>
          <Route path="/onboarding/:integration/successful" element={<Layout>
            <SuccessfulIntegration/>
          </Layout>}
          />
        </Routes>
      </Whitelist>
    </Router>
  </div>);
}

export default App;
