import React from 'react';
import GuildInviteQR from "./GuildInviteQrCode";

interface QrCodeModalProps {
  communityName: string,
  platform?: string,
  onClose: () => void,
  channel_id?: any
}

const QrCodeModal: React.FC<QrCodeModalProps> = ({communityName, platform, onClose, channel_id}) => {
  return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="relative bg-dark p-6 rounded-lg shadow-lg max-w-sm w-full">
      <div className="text-center mb-6">
        <h2 className="text-white text-2xl font-bold">Start integrating with {platform}</h2>
        <p className="text-gray-400 mt-2">Scan the QR code below to get started:</p>
      </div>
      <div className="flex justify-center mb-4">
        <GuildInviteQR communityName={communityName} integrationName={platform} channelId={channel_id}/>
      </div>
      <button
        className="absolute top-2 right-2 text-white text-2xl font-bold focus:outline-none"
        onClick={onClose} // Call onClose when the close button is clicked
      >
        &times;
      </button>
    </div>
  </div>);
};

export default QrCodeModal;


