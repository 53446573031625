import React, {useEffect} from 'react';
import {useLocation, useParams, useNavigate} from 'react-router-dom';

// Define the enum for integration types
const IntegrationType = {
  DISCORD: 'Discord', WHATSAPP: 'WhatsApp', TELEGRAM: 'Telegram',
};

const SuccessfulIntegration = () => {
  const location = useLocation();
  const {integration} = useParams();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const communityOnboarded = params.get('state');
  useEffect(() => {
    const integrationLowerCase = integration.toLowerCase();
    if (integrationLowerCase === IntegrationType.DISCORD.toLowerCase()) {
      const code = params.get('code');
      const guildId = params.get('guild_id');
      const permissions = params.get('permissions');

      // Log the extracted parameters
      console.log('Code:', code);
      console.log('Community Onboarded:', communityOnboarded);
      console.log('Guild ID:', guildId);
      console.log('Permissions:', permissions);

      // Define the necessary data for the API request
      const apiKey = 'your-api-key';
      const inviteUrl = 'your-invite-url';

      // Perform the API request to update integration settings
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${communityOnboarded}/integrations/Discord`, {
        method: 'PUT', headers: {
          'Content-Type': 'application/json',
        }, body: JSON.stringify({
          api_key: apiKey, invite_url: inviteUrl, guild_id: guildId, channel_id: '1246734371780694049'
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle success, e.g., show a notification or update the state
          console.log('Settings updated:', data);
          // Navigate to dashboard or another page if needed
        })
        .catch((error) => {
          console.error('Error updating settings:', error);
        });
    }
  }, [location, integration, navigate]);

  return (<div className="flex flex-col items-center justify-center h-screen bg-gray-100">
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full text-center">
      <h2 className="text-3xl font-bold text-gray-800 mb-4">Integration Successful!</h2>
      <p className="text-lg text-gray-600 mb-6">
        {integration.charAt(0).toUpperCase() + integration.slice(1)} integration completed successfully.
      </p>
      <button
        onClick={() => navigate(`/`)}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
      >
        Go to Dashboard
      </button>
    </div>
  </div>);
};

export default SuccessfulIntegration;
