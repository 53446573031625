// api.ts
import {Channel, Guild} from "../types/community";

export const findGuildData = async (communityName: string, integrationName: string, guildId: string): Promise<Guild> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${communityName}/integrations/${integrationName}/guild/${guildId}`, {
      method: 'GET', headers: {
        'Content-Type': 'application/json',
      },
    });
    const data: Guild = await response.json();
    console.log('Guild Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching guild information:', error);
  }
};

export const findChannelData = async (communityName: string, integrationName: string, guildId: string): Promise<Channel[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${communityName}/integrations/${integrationName}/${guildId}/channels`, {
      method: 'GET', headers: {
        'Content-Type': 'application/json',
      },
    });
    const data: Channel[] = await response.json();
    console.log('Channel Data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching channel information:', error);
  }
};
