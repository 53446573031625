import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {useGetUser, useIsLoggedIn} from '../utils/auth';
import PricingBox from '../Pricing/PricingBox';
import OfferList from '../Pricing/OfferList';
import {Community} from '../../types/community';
import QRCodeComponent from '../Subscription/QRCode';
import {User} from "@auth0/auth0-react";
import CommunityAdminPanel from "../community-admin-panel";
import SigninPage from "../signin/page";

async function saveSubscriber(user: User, integrationName: string, community: Community) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/${community.name}/subscribe`, {
    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
      community: community.name,
      integration: integrationName,
      login_sub: user.sub,
      firstname: user.name,
      lastname: user.family_name
    })
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }
}

const CommunityPage = () => {
  const [isMonthly] = useState(true);
  const {communityName} = useParams<{ communityName: string }>();
  const [community, setCommunity] = useState<Community | null>(null);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [showQrCode, setShowQrCode] = useState<Record<string, boolean>>({});
  const [showModal, setShowModal] = useState(false);
  const user = useGetUser();
  const isLoggedIn = useIsLoggedIn();
  const visibleToPublic = useIsLoggedIn();
  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/`, {
          method: 'GET', headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) throw new Error('Failed to get community.');

        const communities = await response.json();
        const community = communities.find(c => c.name === communityName);

        if (!community) {
          toast.error('Community not found.');
          return;
        }

        setCommunity(community);
      } catch (error) {
        console.error(error);
        toast.error('An error occurred while fetching the community.');
      }
    };

    fetchCommunity();
  }, [communityName]);

  const handleQrCodeDisplay = (integrationName: string) => {
    saveSubscriber(user, integrationName, community).catch((error) => {
      console.error(error);
      toast.error(error.message);
    });
    setShowQrCode({[integrationName]: true});
    setShowModal(true);

  };

  if (!isLoggedIn) return <SigninPage/>;

  return visibleToPublic && (<section id="pricing" className="relative z-10 py-16 md:py-20 lg:py-28">
    <div className="container">
      <div className="w-full">
        {community && community.owner === user.sub && (<>
          <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-5 rounded-lg shadow-lg text-white">
            <h3 className="text-2xl font-bold mb-2">{community?.name}</h3>
            <p className="text-opacity-80">{community?.description}</p>
          </div>
          <br></br>
          <button
            className="flex w-1/4 items-center justify-center rounded-sm bg-primary p-3 text-base font-semibold text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:shadow-signUp"
            onClick={() => setShowAdminPanel(prevState => !prevState)}
          >
            Admin Panel
          </button>
          <br></br>
          {showAdminPanel && <CommunityAdminPanel community={community}/>}
        </>)}
      </div>
      {!showAdminPanel && (<div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-2 lg:grid-cols-3">
        {["Basic", "Premium", "Ultimate"].map((packageName, index) => {
          const packageDetails = {
            Basic: {
              price: isMonthly ? "30" : "360",
              subtitle: "Basic gym access and free group classes and 1 personal trainer session per month",
              offers: [{text: "Gym Access", status: "active"}, {
                text: "Group Classes", status: "active"
              }, {text: "Personal Trainer Sessions", status: "inactive"}, {
                text: "Nutrition Consultation", status: "inactive"
              }, {text: "24/7 Support", status: "inactive"},],
            }, Premium: {
              price: isMonthly ? "50" : "600",
              subtitle: "Gym access, free group classes, and 4 personal trainer sessions per month",
              offers: [{text: "Gym Access", status: "active"}, {
                text: "Group Classes", status: "active"
              }, {text: "Personal Trainer Sessions", status: "active"}, {
                text: "Nutrition Consultation", status: "inactive"
              }, {text: "24/7 Support", status: "inactive"},],
            }, Ultimate: {
              price: isMonthly ? "70" : "840",
              subtitle: "All-inclusive gym package with daily personal trainer sessions and nutrition consultation",
              offers: [{text: "Gym Access", status: "active"}, {
                text: "Group Classes", status: "active"
              }, {text: "Personal Trainer Sessions", status: "active"}, {
                text: "Nutrition Consultation", status: "active"
              }, {text: "24/7 Support", status: "active"},],
            },
          }[packageName];
          return (<PricingBox
            key={index}
            packageName={packageName}
            price={packageDetails.price}
            duration={isMonthly ? "mo" : "yr"}
            subtitle={packageDetails.subtitle}
          >
            {packageDetails.offers.map((offer, idx) => (

              <OfferList key={idx} text={offer.text} status="active"/>))}
            <button
              className="mt-4 w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
              onClick={() => {
                const onboardedIntegration = community.integrations.find(i => i.onboarding_successful);
                if (onboardedIntegration) {
                  handleQrCodeDisplay(onboardedIntegration.name);
                } else {
                  toast.error('No platform is onboarded. Please contact the community owner.');
                }
              }}
            >
              Join the community!
            </button>
          </PricingBox>);
        })}
      </div>)}
      {Object.keys(showQrCode).find(key => showQrCode[key] === true) && (<button onClick={() => setShowModal(true)}
                                                                                 className="mt-4 w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600">
        Show QR Code
      </button>)}
      {showModal && (<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative bg-dark p-6 rounded-lg shadow-lg max-w-sm w-full">
          <button
            className="absolute top-2 right-2 text-gray-700 text-2xl font-bold focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            &times;
          </button>
          <p>here down</p>

          <QRCodeComponent
            communityName={community.name}
            platform={community?.integrations.find(i => i.onboarding_successful)?.name || ''}
            channel_id={community?.integrations.find(i => i.onboarding_successful)?.channel_id || ''}
            onClose={() => setShowModal(false)}/>
          <button
            className="mt-4 bg-blue-500 text-white p-3 rounded-lg shadow-lg w-full focus:outline-none hover:bg-blue-600"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
        </div>
      </div>)}


    </div>
  </section>);
};

export default CommunityPage;
