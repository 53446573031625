import React, {useState, useEffect} from "react";
import {useGetUser} from "../utils/auth";
import {Community} from "../../types/community";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {FaCheckCircle, FaSpinner} from "react-icons/fa";

const CommunityBuilder = () => {
  const currentUser = useGetUser();
  const [communityName, setCommunityName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchCommunities();
  }, []);

  const fetchCommunities = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/`);
      if (!response.ok) {
        throw new Error("Failed to fetch communities.");
      }
      const data = await response.json();
      setCommunities(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch communities.");
    }
  };

  const toggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  const handleCommunityCreation = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!communityName || !description) {
      toast.error("Community name and description are required.");
      return;
    }
    setIsLoading(true);
    try {
      const newCommunity: Community = {
        name: communityName, description, owner: currentUser.sub, integrations: [{
          name: 'WhatsApp',
          onboarding_successful: false,
          api_key: '',
          created_at: new Date(),
          invite_url: "",
          guild_id: "",
          channel_id: ""
        }, {
          name: 'Discord',
          onboarding_successful: false,
          api_key: '',
          created_at: new Date(),
          invite_url: "",
          guild_id: "",
          channel_id: ""
        }, {
          name: 'Telegram',
          onboarding_successful: false,
          api_key: '',
          created_at: new Date(),
          invite_url: "",
          guild_id: "",
          channel_id: ""
        }]
      };
      await createCommunity(newCommunity);
      setSuccess(true);
      toast.success("Community created successfully!");
      setCommunityName("");
      setDescription("");
      setIsLoading(false);
      setShowForm(false);
      fetchCommunities();
    } catch (error) {
      console.error(error);
      toast.error("Failed to create community.");
      setIsLoading(false);
    }
  };

  const createCommunity = async (newCommunity: Community) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/community/`, {
        method: 'POST', headers: {
          'Content-Type': 'application/json',
        }, body: JSON.stringify(newCommunity),
      });
      if (!response.ok) {
        throw new Error("Failed to create community.");
      }
    } catch (error) {
      throw error;
    }
  };

  return (

    <div className="bg-gray-800 p-10 rounded-lg">
      <div className="flex flex-wrap justify-center sm:space-x-0 md:space-x-4 mt-10">
        {communities.map((community) => (<Link to={`/community/${community.name}`} key={community.name}
                                               className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
          <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-5 rounded-lg shadow-lg text-white m-2">
            <h3 className="text-2xl font-bold mb-2">{community?.name}</h3>
            <p className="text-opacity-80">{community?.description}</p>
            <p
              className="text-opacity-80">{currentUser.sub === community?.owner ? '✅ You are owning this' : '❌ You are not owning this'}</p>
          </div>
        </Link>))}
      </div>
      <div className="flex items-center justify-center mt-4">
        <button
          onClick={toggleForm}
          className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
        >
          {showForm ? "Close" : "Create a new community"}
        </button>
      </div>

      {showForm && (<form onSubmit={handleCommunityCreation} className="space-y-4 mt-4 w-full md:w-1/3 mx-auto">
        <div>
          <label htmlFor="communityName" className="block text-sm text-white mb-2">Community name:</label>
          <input
            type="text"
            id="communityName"
            value={communityName}
            onChange={(e) => setCommunityName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm text-white mb-2">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded h-20"
          />
        </div>
        <h1>
          {isLoading ? <FaSpinner className="animate-spin"/> : isLoading ? <FaCheckCircle/> : ""}
        </h1>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
        >
          {isLoading ? "Creating..." : "Create a community"}
        </button>
      </form>)}
    </div>)

};

export default CommunityBuilder;
